import React, { useState, useEffect } from "react";
import Seo from "../components/seo/seo";
import "../scss/AccessibilityPage.scss";

import BubblesBgHeader from "../shared/bubbles-bg-header/BubblesBgHeader";

const AccessibilityPage = () => {
  return (
    <div className="accessibility-page">
      <Seo title="Accessibility -" />
      <BubblesBgHeader text="Accessibility Statement" />
      <div className="content-block container mt-5">
        <section>
          <h2>Our Commitment</h2>
          <p>
            We are dedicated to ensuring accessibility for everyone, including
            persons with disabilities, to have full and equal access to our
            digital offerings.
          </p>
        </section>

        <section>
          <h2>Feedback</h2>
          <p>
            We value your feedback on your experience with{" "}
            <a href="https://gotbubbles.com/">gotBubbles</a>. Please share your
            thoughts or report any issues by emailing us at{" "}
            <a href="mailto:info@gotbubbles.com">info@gotbubbles.com</a>.
          </p>
        </section>

        <section>
          <h2>Standards</h2>
          <p>
            <a href="https://gotbubbles.com/">gotBubbles</a> strives to conform
            to the following accessibility standards: Web Content Accessibility
            Guidelines (WCAG) 2.1 AA, PDF/Universal Accessibility (PDF/UA).
          </p>
        </section>

        <section>
          <h2>Compatibility</h2>
          <p>
            <a href="https://gotbubbles.com/">gotBubbles</a> is compatible with
            most major Internet browsers, including Chrome, Firefox, and Safari.
            However, optimal performance may not be guaranteed on Internet
            Explorer and older browser versions.
          </p>
        </section>

        <section>
          <h2>Distribution</h2>
          <p>
            This policy is distributed to all personnel working with web
            content, including writers, editors, designers, and developers at{" "}
            <a href="https://gotbubbles.com/">gotBubbles</a>.
          </p>
        </section>

        <section>
          <h2>Assessment Approach</h2>
          <p>
            We assessed the accessibility of{" "}
            <a href="https://gotbubbles.com/" target="_blank">
              https://gotbubbles.com/
            </a>{" "}
            using the following approaches:
          </p>
          <ul>
            <li>Manual testing.</li>
            <li>Automated accessibility testing tools.</li>
            <li>Regular review of new content to ensure ongoing compliance.</li>
          </ul>
        </section>

        <section>
          <h2>Testing</h2>
          <p>
            <a href="https://gotbubbles.com/">gotBubbles</a> engages in regular
            automated testing and annual/bi-annual manual audits and user
            testing to assess and improve accessibility.
          </p>
        </section>

        <section>
          <h2>Training</h2>
          <p>
            Web content personnel at{" "}
            <a href="https://gotbubbles.com/">gotBubbles</a> undergo mandatory
            bi-annual accessibility training to enhance their understanding and
            implementation of accessibility best practices.
          </p>
        </section>

        <section>
          <h2>Compliance</h2>
          <p>
            Through genuine effort, commitment, testing, audits, consulting, and
            remediation, we believe{" "}
            <a href="https://gotbubbles.com/">gotBubbles</a> adheres to best
            practices for compliance with the Americans with Disabilities Act
            (ADA).
          </p>
        </section>

        <section>
          <h2>Resources</h2>
          <p>
            For more information on web accessibility and legal requirements,
            here are helpful resources:
          </p>
          <ul>
            <li>
              <a href="https://www.ada.gov/" target="_blank">
                ADA.gov
              </a>
            </li>
            <li>
              <a href="https://www.section508.gov/" target="_blank">
                Section508.gov
              </a>
            </li>
            <li>
              <a href="https://www.accessible.org/" target="_blank">
                Accessible.org
              </a>
            </li>
            <li>
              <a href="https://www.w3.org/WAI/" target="_blank">
                W3.org/WAI
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default AccessibilityPage;
